import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '首页',
    },
    component: () => import('@/views/index.vue')
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      title: '首页',
    },
    component: () => import('@/views/index.vue')
  },
  {
    path: '/product',
    name: 'product',
    meta: {
      title: '产品中心',
    },
    component: () => import('@/views/product.vue')
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      title: '新闻中心',
    },
    component: () => import('@/views/news.vue')
  },
  {
    path: '/newsDetails',
    name: 'newsDetails',
    meta: {
      title: '新闻中心-详情',
    },
    component: () => import('@/views/newsDetails.vue')
  },
  {
    path: '/clinical',
    name: 'clinical',
    meta: {
      title: '临床案例',
    },
    component: () => import('@/views/clinical.vue')
  },
  {
    path: '/clinicalDetails',
    name: 'clinicalDetails',
    meta: {
      title: '临床案例-详情',
    },
    component: () => import('@/views/clinicalDetails.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      title: '联系我们',
    },
    component: () => import('@/views/contact.vue')
  },
  {
    path: '/recruit',
    name: 'recruit',
    meta: {
      title: '人才招聘',
    },
    component: () => import('@/views/recruit.vue')
  },
  {
    path: '/recruitDetails',
    name: 'recruitDetails',
    meta: {
      title: '人才招聘-列表',
    },
    component: () => import('@/views/recruitDetails.vue')
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '关于我们',
    },
    component: () => import('@/views/about.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  window.scrollTo(0, 0)
  if (to.meta.title) {
    document.title = '邦杰星-'+to.meta.title;
  }
  next();
});

export default router
