import http from './service.js'
// 新闻列表
export const getNewList = (data) => {
    return http({
        url: '/blog/api/article/list',
        method: 'post',
        data:{...data}
    })
     
}
// 浏览记录
export const getNewLookList = (data) => {
    return http({
        url: '/blog/api/article/look',
        method: 'post',
        data:{...data}
    })
     
}
// 保存留言
export const addMessage = (data) => {
    return http({
        url: '/blog/api/comment/save',
        method: 'post',
        data:{...data}
    })
     
}
// 招聘信息列表
export const getJobList = (data) => {
    return http({
        url: '/blog/api/job/list',
        method: 'post',
        data:{...data}
    })
     
}
// 专利信息列表
export const getPatentList = (data) => {
    return http({
        url: '/blog/api/patent/list',
        method: 'post',
        data:{...data}
    })
     
}
// 病案信息列表
export const getCaseList = (data) => {
    return http({
        url: '/blog/api/case/list',
        method: 'post',
        data:{...data}
    })
     
}