<template>
  <div :class="['header-warp',scrollTop > 180 ? 'scrollTop' : '']">
    <div class="header">
      <img v-show="scrollTop < 180" src="@/assets/images/logo.png" alt="">
      <img v-show="scrollTop > 180" src="@/assets/images/logo-blue.png" alt="">
      <ul class="nav-list">
        <li v-for="(item) in navList" :key="item.name" @click="changeNav">
          <router-link  :to="item.name" custom>
            <div :class="onName == item.name ? 'active' : ''">
              {{item.meta.title}}
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'headers',
    components: {
    },
    data(){
      return {
        scrollTop:0,
        navList:[],
        onName:'index'
      }
    },
    created(){
      this.navList = this.$router.options.routes.filter(item => item.name != 'home')
      this.navList = this.navList.filter(item => item.name != 'newsDetails')
      this.navList = this.navList.filter(item => item.name != 'clinicalDetails')
      this.navList = this.navList.filter(item => item.name != 'recruitDetails')
      document.addEventListener('scroll',this.Scroll, true)
      this.changeNav()
    },
    mounted(){
    },
    methods:{
      Scroll(){
        this.scrollTop = document.querySelector('html').scrollTop
      },
      changeNav(){
        this.onName = this.$route.name ? this.$route.name : 'index'
      }
    },
    watch:{
      '$route': 'changeNav'
    }
  }
</script>
<style lang="less" scoped>
.header-warp {
  width: 100%;
  height: 80px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
  position:fixed;
  top:0;
  left:0;
  z-index:10000;
  .header{
    width: 1200px;
    height:80px;
    line-height: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation-duration: 2s;
    animation-name: dh3;
    img{
      width: 162px;
      height: 45px;
      display: inline-block;
    }
  }
}
.scrollTop{
  background: rgba(255, 255, 255, 1);
  .header{
    .nav-list{
      li{
        div{
          color:#333;
          display: inline-block;
        }
      }
    }
  }
}
a:hover{
  text-decoration: none !important;
}
.nav-list{
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition:all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) ;
  li{
    color: #fff;
    cursor: pointer;
    margin-left: 50px;
    font-weight: 500;
    font-size: 18px;
    text-align: right;
    div{
      color: #fff;
      &.active,&:hover{
        position: relative;
        color:#4a78bc !important;
        &::before{
          content: "";
          display: block;
          width: 100%;
          height: 3px;
          background: #4a78bc;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
}
</style>