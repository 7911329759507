<template>
  <div class="footer-box">
    <div class="footer">
        <dl>
          <dt>首页</dt>
          <dd @click="$router.push({name:'about'})">公司简介</dd>
          <dd @click="$router.push({name:'product'})">产品中心</dd>
          <dd @click="$router.push({name:'product'})">产品亮点</dd>
          <dd @click="$router.push({name:'news'})">新闻中心</dd>
          <dd @click="$router.push({name:'index'})">合作伙伴</dd>
        </dl>
        <dl>
          <dt @click="$router.push({name:'about'})">关于我们</dt>
          <dd @click="$router.push({name:'about'})">公司简介</dd>
          <dd @click="$router.push({name:'about'})">发展历程</dd>
          <dd @click="$router.push({name:'about'})">企业专利</dd>
        </dl>
        <dl>
          <dt @click="$router.push({name:'product'})">产品中心</dt>
          <dd @click="$router.push({name:'product'})">产品介绍</dd>
          <dd @click="$router.push({name:'product'})">核心优势</dd>
        </dl>
        <dl>
          <dt @click="$router.push({name:'news'})">新闻中心</dt>
          <dd @click="$router.push({name:'news'})">新闻动态</dd>
        </dl>
        <dl class="bold-dl">
          <dt>联系我们</dt>
          <dd>服务热线：0571-8639 5189</dd>
          <dd>联系邮箱：he@Bond-Star.onaliyun.com</dd>
          <dd>联系地址：星运医疗健康产业园7号楼1幢5层502室</dd>
        </dl>
        <dl class="qrcode">
          <dt><img src="@/assets/images/qrcode.png" alt=""></dt>
          <dd>扫一扫</dd>
        </dl>
    </div>
    <div class="copyright">
      Copyright&nbsp;&nbsp;©&nbsp;&nbsp;杭州邦杰星医疗科技有限公司&nbsp;&nbsp;|&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/">浙ICP备2024128984号-1</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'footers',
    components: {
    },
    data () {
      return {
      }
    },
    created () {
    },
    mounted () {
    },
    methods: {
    }
  }
</script>
<style lang="less" scoped>
.footer-box{
  background: #F7F8FA;
}
.footer{
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 70px 0;
  dl{
    text-align: left;
    dt{
      font-size: 18px;
      font-weight: bold;
      color: #333;
      margin-bottom: 40px;
      line-height: 1;
    }
    dd{
      margin-bottom: 18px;
      font-size: 14px;
      color: #333;
      cursor: pointer;
    }
  }
  dl.bold-dl{
    dt{
      font-weight: bold;
      font-size: 28px;
      margin-bottom: 25px;
    }
    dd{
      font-size: 18px;
    }
  }
  dl.qrcode{
    text-align: center;
    dt{
      width: 157px;
      height: 157px;
      margin: 0 auto;
      img{
        width: 100%;
        height: 100%;
      }
    }
    dd{
      font-size: 20px;
      color: #333;
    }
  }
}
.copyright{
  text-align: center;
  font-size: 14px;
  color: #333333;
  height: 40px;
  line-height: 40px;
  background: #EDF0F3;
  a:hover{
    color: #4a78bc;
  }
}
</style>
