<template>
  <div class="warp">
    <div class="banner" v-intersect="handleIntersect"></div>
    <div class="content">
      <!-- 公司介绍 -->
      <div class="item-1" v-intersect="handleIntersect">
        <img class="item-1-left" src="@/assets/images/img-01.png" alt="">
        <div class="item-1-right">
          <p class="p1">杭州邦杰星医疗科技有限公司</p>
          <p>
            杭州邦杰星医疗科技有限公司是一家专注于骨科医疗领域的高新技术型企业,集研发、生产销售为一体,致力于成为骨科医疗领域领军者。公司秉承“始"于临床、“精”于骨科,致力于人工智能与临床需求深度融合,着力布局手术机器人产业生态,为医院提供人工智能辅助诊断、个体化手术计划、手术导航机器人系统、术后评估等全流程解决方案。
          </p>
          <p>
            公司打造医学与工科深度结合的创新体系,研发团队来自新加坡南洋理工大学,北京航空航天大学、上海交通大学、浙江大学、厦门大学、东南大学等世界一流院校,并与浙江大学医学院附属第一医院、浙江大学医学院附属第二医院、重庆医科大学附属第二医院、上海同仁医院、上海长海医院、上海长征医院、解放军301医院等构建了稳定高效的产、学、研、医紧密结合的科研创新平台。公司以“科技精准,赋能临床,国邦骨医,杰出之星”为愿景,开创术前内植入物精准规划、术中实时导航追踪、术后效果反馈的脊柱外科手术导航定位系统,降低脊柱手术难度,通过智能的算法、精准的定位为医患赋能。
          </p>
          <div>
            <div class="p2">
              <span>7<b>处</b></span>
              <p>全国临床中心</p> 
            </div>
            <div class="p2">
              <span>100<b>+</b></span>
              <p>合作伙伴</p>
            </div>
          </div>
        </div>
      </div>
      <div class="item-2">
        <img src="@/assets/images/img-02.png" alt="">
      </div>
      <!-- 产品介绍 -->
      <div class="item-3">
        <div class="title">
          <span>产品</span>中心
        </div>
        <div class="item-3-1" v-intersect="handleIntersect">
          <div class="item-text">
            <p>星耀 —— 定位台车</p>
            <p>六自由度机械臂,摆位灵活多样化,手术操作空间范围大,满足脊柱各种角度的穿刺和置钉需求,具备超高的稳定度与精准度,完美解决了人手不稳定的因素。</p>
            <span @click="$router.push('/product')">查看更多</span>
          </div>
          <img class="carright" src="@/assets/images/img-03.png" alt="">
        </div>
        <div class="item-3-2" v-intersect="handleIntersect">
          <img class="img-left carleft"  src="@/assets/images/img-04.png" alt="">
          <div></div>
          <div class="item-text">
            <p>星耀 —— 定位台车</p>
            <p>六自由度机械臂,摆位灵活多样化,手术操作空间范围大,满足脊柱各种角度的穿刺和置钉需求,具备超高的稳定度与精准度,完美解决了人手不稳定的因素。</p>
            <span @click="$router.push('/product')">查看更多</span>
          </div>
        </div>
      </div>  
      <!-- 亮点 -->
      <div class="item-4">
        <div class="title">
          <span>产品</span>亮点
        </div>
        <div class="item-4-1">
          <div>
            <img src="@/assets/images/icon-01.png" alt="">
            <p>自动分割与标识</p>
            <p>利用深度学习人工智能算法自动完成CT影像的分割与椎节的识别标注,为图像配准提供精度保障</p>
          </div>
          <div>
            <img src="@/assets/images/icon-02.png" alt="">
            <p>全自动图像配准技术</p>
            <p>自动完成术前影像跟术中图像的自动配准，配准时间高效，配准结果精确</p>
          </div>
          <div>
            <img src="@/assets/images/icon-03.png" alt="">
            <p>硬件成本降低</p>
            <p>无需术中三维C设备，无需全碳纤维手术床，医院硬件成本可以节约300-500万</p>
          </div>
          <div>
            <img src="@/assets/images/icon-04.png" alt="">
            <p>手术全流程支持</p>
            <p>支持克氏针置入导航、丝锥扩张通道攻丝过程导航、持钉器置钉导航</p>
          </div>
          <div>
            <img src="@/assets/images/icon-05.png" alt="">
            <p>术前规划同步到术中影像</p>
            <p>术前规划的螺钉信息实时同步投影到术中正位、侧位图像上，让手术更加直观</p>
          </div>
          <div>
            <img src="@/assets/images/icon-06.png" alt="">
            <p>耗材</p>
            <p>具备一次性耗材跟重复使用耗材, 帮助医院加快成本回收</p>
          </div>
        </div>
      </div>
      <!-- 新闻中心 -->
      <div class="item-5">
        <div class="title">
          <span>新闻</span>中心
        </div>
        <div class="item-5-1">
          <div class="new-left-warp">
            <div 
            :class="['new-left', showIndex == index? 'on': '']" 
            @click="$router.push('/news')" 
            v-for="(item,index) in leftList" :key="index"
            @mouseover.stop.prevent="isStop = true;" 
            @mouseleave.stop.prevent="isStop = false;">
              <img :src="require(`../assets/images/news/${item.coverImage}`)" alt="">
              <div class="new-left-time">
                <span>{{ item.createTime.split(' ')[0].split('-')[2]}}</span>
                <p>{{ item.createTime.split('-')[0]}}-{{ item.createTime.split('-')[1]}}</p>
              </div>
              <div class="new-left-detail">
                <p>{{ item.title }}</p>
                <p>{{ item.desc }}</p>
              </div>
            </div>
          </div>
          <div class="new-list">
            <div class="new-list-item" 
            v-for="(item, index) in newsList" :key="item.id"
            @click="$router.push({path: '/newsDetails', query: {id: index}})" 
            @mouseover.stop.prevent="changeNews(index)" 
            @mouseleave.stop.prevent="mouseleaveFun">
              <div class="new-list-item-time">
                
                <span>{{ item.createTime.split(' ')[0].split('-')[2]}}</span>
                <p>{{ item.createTime.split('-')[0]}}-{{ item.createTime.split('-')[1]}}</p>
              </div>
              <div class="new-list-item-detail">
                <p>{{ item.title }}</p>
                <p>{{ item.desc }}</p>
              </div>
            </div>
          </div>
          <div class="more" @click="$router.push('/news')">了解更多</div>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="item-6">
        <div class="title">
          <span>合作</span>伙伴
        </div>
        <div class="item-6-1">
          <div>
            <img src="@/assets/images/s-01.png" alt="">
          </div>
          <div>
            <img src="@/assets/images/s-02.png" alt="">
          </div>
          <div>
            <img src="@/assets/images/s-03.png" alt="">
          </div>
          <div>
            <img src="@/assets/images/s-04.png" alt="">
          </div>
          <div>
            <img src="@/assets/images/s-05.png" alt="">
          </div>
          <div>
            <img src="@/assets/images/s-06.png" alt="">
          </div>
          <div>
            <img src="@/assets/images/s-07.png" alt="">
          </div>
          <div>
            <img src="@/assets/images/s-08.png" alt="">
          </div>
          <div>
            <img src="@/assets/images/s-09.png" alt="">
          </div>
          <div>
            <img src="@/assets/images/s-10.png" alt="">
          </div>
          <div>
            <img src="@/assets/images/s-11.png" alt="">
          </div>
          <div>
            <img src="@/assets/images/s-12.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {getNewList} from '@/api/index.js'
import {newsData} from '@/util/newsData.js'
import 'animate.css';
export default {
  name: 'index',
  components: {
  },
  data(){
    return {
      leftList:[],
      newsList:[],
      showIndex:0,
      isStop:false,
      timer:null,
    }
  },
  created(){
  },
  mounted(){
    this.getNewListFun()
    this.timer = setInterval(()=>{
      if(this.isStop){
        return
      }
      this.showIndex = this.showIndex + 1;
      if(this.showIndex == this.leftList.length){
        this.showIndex = 0;
      }
    }, 2000)
    
  },
  unmounted(){
    clearInterval(this.timer)
  },
  methods:{
    handleIntersect(entry, status) {
      if (status === 'enter') {
        this.$nextTick( () => {
          // 在这里执行元素进入视图后的操作，比如加载数据、触发动画等
          if(entry.target.classList.contains('item-1')){
            setTimeout(()=>{
              document.querySelector('.item-1-left').classList.add('animate__animated','animate__fadeInUp','animate__delay-1.5s')
              document.querySelector('.item-1-right').classList.add('animate__animated','animate__fadeInUp','animate__delay-1.5s')
            },1000)
          }
          if(entry.target.classList.contains('item-3-1')){
            setTimeout(()=>{
              document.querySelector('.carright').classList.add('animate__animated','animate__fadeInRight','animate__delay-1.5s')
            },500)
          }
          if(entry.target.classList.contains('item-3-2')){
            setTimeout(()=>{
              document.querySelector('.carleft').classList.add('animate__animated','animate__fadeInLeft','animate__delay-1.5s')
            },500)
          }
        })
      } else if (status === 'exit') {
      }
    },
    mouseleaveFun(e){
      e.preventDefault()
      e.stopPropagation()
      this.isStop = false
    },
    changeNews(idx){
      this.showIndex = idx
      this.isStop = true
      // this.leftList[0] = item
    },
    getNewListFun(){
      // let that = this
      // getNewList({
      //   pageNumber:1,
      //   pageSize: 10,
      // })
      // .then(function (res) {
      //   that.newList = res.rows
      //   that.newList.forEach((item,index)=>{
      //     item.createTime = item.createTime.split(' ')[0]
      //   })
      //   that.leftList[0] = that.newList[0]
      // })
      // .catch(function (error) {
      //   console.log(error);
      // });
      newsData.forEach((item,index)=>{
        if(index<3){
          this.newsList.push(item)
          this.leftList.push(item)
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
.warp{
  width: 100%;
}
.banner{
  width: 100%;
  height: 780px;
  background: url('@/assets/images/banner.png') no-repeat center center;
  background-size: cover;
  animation: changeScale 1 2.5s;
}
.content{
  margin: 0 auto;
  width: 1200px;
}
.item-1{
  padding-top: 220px;
  img{
    width: 471px;
    height: 391px;
    margin-right: 110px;
    vertical-align: middle;
    opacity: 0;
  }
  div{
    vertical-align: top;
    opacity: 0;
    display:inline-block;
    width:614px;
    >p{
      font-family: Alibaba PuHuiTi 2.0;
      font-weight: normal;
      font-size: 14px;
      color: #262626;
      margin-bottom: 33px;
      &.p1{
        font-weight: bold;
        font-size: 30px;
        color: #4a78bc;
        line-height: 1;
        margin-top: -18px;
      }
    }
    >div{
      text-align: center;
      .p2{
        display: inline-block;
        width: 200px;
        font-size: 22px;
        color: #343434;
        span{
          display: block;
          font-weight: normal;
          font-size: 60px;
          color: #4a78bc;
          line-height: 1;
          b{
            font-style: none;
            font-size: 24px;
            font-weight: bold;
          }
        }
        p{
          opacity: 0.6;
        }
      }
    }
  }
}
.item-2{
  padding-top: 62px;
}
.title{
  font-size: 50px;
  color:#333;
  line-height: 63px;
  span{
    color: #4a78bc;
  }
}
.item-3{
  margin-top: 135px;
  .title{
    margin-bottom: 78px;
    font-size: 42px;
    font-weight: bold;
  }
  .item-3-1{
    background: #F7F8FA;
    border-radius: 10px;
    height: 338px;
    margin-bottom: 342px;
    padding:72px 71px 40px 72px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    img{
      opacity: 0;
      position:absolute;
      cursor: pointer;
      top: -105px;
      right:0;
      &:hover{
        transform: scale(1.1) !important;
      }
    }
    p{
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 28px;
      color: #333333;
      line-height: 63px;  
    }
    p:nth-child(2){
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 15px;
      color: #333333;
      opacity: 0.5;
      width:550px;
      line-height: 2;
      margin-bottom: 80px;
    }
    span{
      background: #F7F8FA;
      border-radius: 30px;
      border: 1px solid #DBE0E4;
      padding:12px 26px;
      cursor: pointer;
      font-size:14px;
      &:hover{
        background-color: #4a78bc;
        border:1px solod #4a78bc;
        color:#fff;
      }
    }
  }
  .item-3-2{
    background: #F7F8FA;
    border-radius: 10px;
    height: 338px;
    margin-bottom: 342px;
    padding:72px 71px 40px 72px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    img{
      opacity: 0;
      position:absolute;
      top: -272px;
      left:104px;
      cursor: pointer;
      &:hover{
        transform: scale(1.1) !important;
      }
    }
    p{
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 28px;
      color: #333333;
      line-height: 63px;
    }
    p:nth-child(2){
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      opacity: 0.5;
      width:550px;
      line-height: 2;
      margin-bottom: 80px;
    }
    span{
      background: #F7F8FA;
      border-radius: 30px;
      border: 1px solid #DBE0E4;
      padding:12px 26px;
      cursor: pointer;
      font-size:14px;
      &:hover{
        background-color: #4a78bc;
        border:1px solod #4a78bc;
        color:#fff;
      }
    }
  }
}
.item-4{
  padding-bottom:169px;
  .title{
    margin-bottom: 78px;
    font-size: 42px;
    font-weight: bold;
  }
  .item-4-1{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:45px;
    div{
      width: 370px;
      height: 244px;
      background: #F7F8FA;
      border-radius: 8px;
      text-align: center;
      padding:36px 30px 25px 30px;
      box-sizing: border-box;
      p:nth-child(2){
        font-weight: 500;
        font-size: 20px;
        color: #333333;
        line-height: 63px;
      }
      p:nth-child(3){
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        opacity: 0.5;
      }
      &:hover{
        scale: 1.1;
      }
    }
  }
}
.item-5{
  padding-bottom: 212px;
  .title{
    margin-bottom: 79px;
    font-size: 42px;
    font-weight: bold;
  }
  .item-5-1{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap:40px;
    position: relative;
    .new-left-warp{
      width: 568px;
      height: 451px;
      position: relative;
      overflow: hidden;
      .new-left{
        width: 568px;
        height: 451px;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        cursor: pointer;
        opacity: 0;
        z-index:1;
        border-radius:8px;
        &.on{
          z-index: 2;
          opacity: 1;
        }
        img{
          width: 100%;
          height: 100%;
        }
        &:hover{
          img{
            transform: scale(1.1);
          }
          .new-left-time,.new-left-detail{
            background: rgba(0,0,0,0.5);
          }
        }
        .new-left-time{
          position: absolute;
          top: 0;
          right: 0;
          width: 99px;
          height: 62px;
          background: rgba(255,255,255,0.5);
          border-radius: 0px 8px 0px 8px;
          font-weight: 500;
          font-size: 24px;
          color: #FFFFFF;
          text-align: center;
          p{
            font-size: 16px;
          }
        }
        .new-left-detail{
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 89px;
          background: rgba(0,0,0,0.1);
          border-radius: 0px 0px 8px 8px;
          padding: 15px 20px;
          box-sizing: border-box;
          color: #FFFFFF;
          p{
            font-size: 20px;
            line-height: 1;
            white-space:nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p:nth-child(2){
            font-size: 14px;
            margin-top: 10px;
          }
        }
      }
    }
    .new-list{
      .new-list-item{
        height: 143px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 33px;
        cursor: pointer;
        &:hover{
          transform:translate(0,-5px);
          div.new-list-item-detail{
            p:nth-child(1){
              color: #4a78bc;
            }
          }
        }
        div.new-list-item-time{
          width:60px;
          padding-top: 57px;
          padding-bottom: 45px;
          border-bottom: 2px solid #4a78bc;
          text-align: right;
          span{
            font-size: 24px;
            color: #333333;
          }
          p{
            font-size: 16px;
            color: #333333;
          }
        }
        div.new-list-item-detail{
          width:499px;
          padding:32px 0;
          p:nth-child(1){
            font-weight: 500;
            font-size: 20px;
            color: #333333;
            margin-bottom: 17px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          p:nth-child(2){
            font-weight: 400;
            font-size: 14px;
            color: rgba(51, 51, 51, 0.5);
            line-height: 22px;
            overflow:hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:2;
          }
        }
      }
    }
    .more{
      position: absolute;
      bottom: -44px;
      right: 0;
      width: 91px;
      height: 37px;
      background: rgba(255,255,255,0.5);
      border-radius: 0px 8px 0px 8px;
      font-size: 16px;
      color: #333333;
      background: #F7F8FA;
      border-radius: 18px;
      border: 1px solid #DBE0E4;
      text-align: center;
      line-height: 37px;
      cursor: pointer;
      &:hover{
        background-color: #4a78bc;
        border: 1px solod #4a78bc;
        color: #fff;
      }
    }
  }
}
.item-7{
  padding-bottom: 113px;
  .title{
    margin-bottom: 78px;
    font-size: 42px;
    font-weight: bold;
  }
  .item-7-1{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:38px;
    div{
    }
  }
}
.item-6{
  padding-bottom: 113px;
  .title{
    margin-bottom: 78px;
    font-size: 42px;
    font-weight: bold;
  }
  .item-6-1{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:38px;
    div{
      width: 268px;
      height: 118px;
      background: #F7F8FA;
      border-radius: 8px;
      text-align: center;
      border:1px solid #DBE0E4;
      overflow: hidden;
      img{
        width: 272px;
        height: 122px;
        margin-top: -2px;
        margin-left: -2px;
        &:hover{
          transform: scale(1.1);
        }
      }
    }
  }
}
</style>