<template>
  <div id="app">
    <Header />
    <router-view/>
    <Footer />
  </div>
</template>
<script>
  import Header from '@/components/header.vue'
  import Footer from '@/components/footer.vue'
  export default {
    name: 'index',
    components: {
      Header,
      Footer
    }
  }
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:100%;
  height:100%;
}

</style>
