import Vue from 'vue';

Vue.directive('intersect', {
  bind(el, binding) {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: [0, 1]
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          binding.value(entry, 'enter'); // 元素进入视图时调用，传递进入状态
        } else {
          binding.value(entry, 'exit'); // 元素退出视图时调用，传递退出状态
        }
      });
    }, options);
    observer.observe(el);
  }
});