export const newsData = [
    {
        title:'良渚新城与TA的“朋友们”② 【杭州邦杰星】骨科手术"特长生"上岗',
        createTime:'2024-05-11 11:03',
        coverImage:'news1.jpg',
        desc:'医院迈入“智慧”时代靠什么？杭州邦杰星医疗科技有限公司（下文简称为“邦杰星医疗”）总裁马震川在“智慧医疗+机器人”中看到了未来已来。'  ,
        content:`<p>医院迈入“智慧”时代靠什么？杭州邦杰星医疗科技有限公司（下文简称为“邦杰星医疗”）总裁马震川在“智慧医疗+机器人”中看到了未来已来。</p>
        <p>“中国创新医疗器械行业经过过去十几年的发展，正在进入成长的快车道。”马震川说， 随着人口老龄化的加剧和医疗需求的不断增长，医疗机器人拥有的巨大发展潜力，将引领外科技术“革命性升级”。</p>
        <img style="margin:0 auto;" src='http://47.97.84.75:8080/files/20240909/news1.jpg'>
        <p>作为一家专注骨科医疗领域的高新技术企业，邦杰星医疗集研发、生产、销售、服务于一体。公司坚持临床需求导向，深耕骨科领域，运用深度学习技术，布局手术机器人产业，为医院提供人工智能辅助的全流程解决方案。</p>
        <p>成为行业领军者，是公司一直以来的奋斗目标。</p>
        <p>而作为企业领导者的马震川，从创业者到企业家，他最大的感触就是，创新与坚持一定是企业“过关斩将”的不二秘诀。</p>
        <p>企业摸索前行、积微成著，先后荣获“国家科技型中小企业”和“省级科技型中小企业”等资质和荣誉，在知识产权方面斩获多项知识产权。</p>
        <p>回顾这几年，企业“招兵买马”，吸引了大量高精尖人才加入，研发团队不断扩大。同时，与国内外知名高校和研究机构建立合作关系，共同破解“卡脖子”技术难题。</p>
        <p>几年如一日，企业研发团队深耕研发一线，创新步履不停。</p>
        <img src='http://47.97.84.75:8080/files/20240909/news2.jpg'>
        <p>在大家的努力下，企业自主研发的骨科手术“特长生”——“星耀”脊柱手术机器人，也随即“闪耀出场”。</p>
        <p>“星耀”是一款集创新、精准与安全于一体的手术辅助设备，它集成了先进的计算机视觉技术、机器人控制技术和医学影像处理技术，能够实现术前规划、术中导航和精准定位。</p>
        <img src='http://47.97.84.75:8080/files/20240909/news3.jpg'>
        <p>跟传统手术相比，机器人将通过精细的自动配准和亚毫米级的定位精度，确保手术过程中的准确性和安全性，有效减少人为误差，提高手术效率。</p>
        <p>正如它的名字一样，“星耀”将成为一颗冉冉升起的新星，在医疗行业里闪烁光芒。</p>
        <p>谈及公司的未来发展，马震川充满了信心：“我们将持续加强技术研发、投入大量资源，进一步完善产品线，推出更多能适应不同需求的医用机器人产品，不断提升产品竞争力和品牌影响力，为医用机器人领域的发展贡献更多的力量。”</p> 
        <img src='http://47.97.84.75:8080/files/20240909/news4.jpg'>
        `,
    },
    {
        title:'【杭州邦杰星】“星耀”—脊柱外科手术导航定位系统',
        createTime:'2024-04-24 17:50',
        coverImage:'news5.jpg',
        desc:'杭州邦杰星医疗科技有限公司是一家专注于骨科医疗领域的高新技术型企业，集研发、生产销售为一体，致力于成为骨科医疗领域领军者。公司秉承“始"于临床、“精”于骨科，致力于人工智能与临床需求深度融合，着力布局手术机器人产业生态，为医院提供人工智能辅助诊断、个体化手术计划、手术导航机器人系统、术后评估等全流程解决方案。'  ,
        content:`<img src='http://47.97.84.75:8080/files/20240909/news5.jpg'>
        <p>杭州邦杰星医疗科技有限公司是一家专注于骨科医疗领域的高新技术型企业，集研发、生产销售为一体，致力于成为骨科医疗领域领军者。公司秉承“始"于临床、“精”于骨科，致力于人工智能与临床需求深度融合，着力布局手术机器人产业生态，为医院提供人工智能辅助诊断、个体化手术计划、手术导航机器人系统、术后评估等全流程解决方案。</p>
        <p>公司打造医学与工科深度结合的创新体系，研发团队来自新加坡南洋理工大学、北京航空、航天大学、上海交通大学、浙江大学、厦门大学、东南大学等世界一流院校，并与浙江大学医学院附属第一医院、浙江大学医学院附属第二医院、重庆医科大学附属第二医院、上海同仁医院、上海长海医院、上海长征医院、解放军301医院等构建了稳定高效的产、学、研、医紧密结合的科研创新平台。</p>
        <p>公司以“科技精准,赋能临床,国邦骨医，杰出之星”为愿景，开创术前内植入物精准规划、术中实时导航追踪、术后效果反馈的脊柱外科手术导航定位系统，降低脊柱手术难度，通过智能的算法，精准的定位为医患赋能。</p>
        <img src='http://47.97.84.75:8080/files/20240909/news6.jpg'>
        <img src='http://47.97.84.75:8080/files/20240909/news7.jpg'>
        <p>脊柱外科手术导航定位系统——星耀</p>
        <p>“星耀”脊柱手术机器人是公司自主研发的一款高精度、智能化的手术辅助设备。它集成了先进的计算机视觉技术、机器人控制技术和医学影像处理技术，能够实现术前规划、术中导航和精准定位。机器人通过精细的自动配准和亚毫米级的定位精度，可以确保手术过程中的准确性和安全性，有效减少人为误差，提高手术效率。</p>
        <img src='http://47.97.84.75:8080/files/20240909/news8.jpg'>
        <img src='http://47.97.84.75:8080/files/20240909/news9.jpg'>`,
    },
    {
        title:'【杭州邦杰星】“星耀”脊柱机器人获批NMPA批准上市！',
        createTime:'2024-04-11 21:02',
        coverImage:'news11.jpg',
        desc:'经国家药品监督管理局批准，杭州邦杰星医疗自主研发的“脊柱外科手术导航定位系统”取得中华人民共和国医疗器械注册证。'  ,
        content:`<img src='http://47.97.84.75:8080/files/20240909/news11.jpg'>
        <p>经国家药品监督管理局批准，杭州邦杰星医疗自主研发的“脊柱外科手术导航定位系统”取得中华人民共和国医疗器械注册证。</p>
        <img src='http://47.97.84.75:8080/files/20240909/news12.jpg'>
        <p>01脊柱机器人产品介绍</p>
        <p>脊柱机器人由组成：“眼”、“脑”、“手”协同工作</p>
        <p>“眼”：光学定位装置</p>
        <p>亚毫米级定位精度，自动完成设备、患者 跟病灶的空间配准，实时跟踪各模块的空间位置；同时追踪手术器械的植入角度跟深度信息，为患者的手术安全保驾护航。</p>
        <p>“脑”：脊柱外科手术导航定位系统软件</p>
        <p>术前规划功能协助医生快速定位病灶，制定完美的手术方案，系统支持图像的自动分割， 椎节的智能标注，全自动图像配准技术。</p>
        <p>“手”：六轴机械臂</p>
        <p>六自由度机械臂，摆位灵活多样化，手术操作空间范围大，满足脊柱各种角度的穿刺和置钉需求，具备超高的稳定度跟精准度，完美解决了人手不稳定因素。</p>
        <p>02脊柱机器人产品优势</p>
        <img src='http://47.97.84.75:8080/files/20240909/news13.jpg'>
        <p>03企业简介</p>
        <p>杭州邦杰星医疗科技有限公司专注于骨科医疗领域的高新技术型企业，集研发、生产、销售为一体，励志成为骨科医疗领域领军者。公司秉承“始"于临床、“精”于骨科，致力于深度学习技术与临床需求深度融合，着力布局手术机器人产业生态，为医院提供人工智能辅助诊断、个体化手术计划、手术导航机器人系统、术后评估等全流程解决方案。</p>
        <p>企业组建医学与工科深度结合，研发团队来自新加坡南洋理工大学、北京航空航天大学、上海交通大学、浙江大学、厦门大学、东南大学等世界一流院校。并与浙江大学医学院附属第一医院、浙江大学医学院附属第二医院、重庆医科大学附属第二医院、上海同仁医院、上海长海医院、上海长征医院、解放军301医院等构建了稳定高效的产、学、研、医紧密结合的科研创新平台。</p>
        <p>公司以“科技精准，赋能临床，国邦骨医，杰出之星”为愿景，开创术前内植入物精准规划，术中实时导航追踪，术后效果反馈的脊柱外科手术导航定位系统，降低脊柱手术难度，通过智能的算法、精准的定位为医患赋能。</p>
        <img src='http://47.97.84.75:8080/files/20240909/news10.jpg'>
        `,
    },
    {
        title:'【杭州邦杰星】和我们一起，追风拾野，趁兴而行',
        createTime:'2023-08-10 14:27',
        coverImage:'news14.jpg',
        desc:'杭州邦杰星开展了一场精彩的户外团建活动。来到杭州径山度假庄园，有宽阔的草地、茂密的树林和清澈的溪流，为大家提供了一个远离城市喧嚣、放松身心的理想场所。'  ,
        content:`<p>杭州邦杰星开展了一场精彩的户外团建活动。来到杭州径山度假庄园，有宽阔的草地、茂密的树林和清澈的溪流，为大家提供了一个远离城市喧嚣、放松身心的理想场所。</p>
        <img src='http://47.97.84.75:8080/files/20240909/news14.jpg'>
        <img src='http://47.97.84.75:8080/files/20240909/news15.jpg'>
        <img src='http://47.97.84.75:8080/files/20240909/news16.jpg'>
        <p>在沉浸式体验风土人情的片段里，让心绪随风荡漾，穿插一些趣味互动，在不断而来的困难里，用汗水雕刻记忆。</p>
        <img src='http://47.97.84.75:8080/files/20240909/news17.jpg'>
        <img src='http://47.97.84.75:8080/files/20240909/news18.jpg'>
        <p>在大富翁游戏环节，大家分组进行比赛，争夺虚拟财产和地皮，欢笑声和欢呼声不断。</p>
        <p>小伙伴们放下了工作的压力和烦恼，全身心地享受这短暂的团建时光。大家互相鼓励、支持，展现了团队的力量和凝聚力。</p>
        <img src='http://47.97.84.75:8080/files/20240909/news19.jpg'>
        <p>这次团建活动不仅增强了小伙伴们的友谊和团队精神，还提高了大家的工作积极性和协作能力。大家在轻松愉快的氛围中提高了自我展示和沟通能力，更加深入地了解了彼此。</p>
        <p>公司的团建出游活动圆满结束，但这次在杭州径山度假庄园的快乐时光将永远留在每个人的心中。它将成为邦杰星公司文化和团队凝聚力的重要组成部分，为未来的发展奠定坚实的基础。</p>
        <img src='http://47.97.84.75:8080/files/20240909/news10.jpg'>`,
    },
    {
        title:'【杭州邦杰星医疗】孟志斌教授莅临杭州邦杰星医疗科技有限公司指导工作',
        createTime:'2023-07-14 15:13',
        coverImage:'news18.jpg',
        desc:'海南医科大孟志斌教授莅临杭州邦杰星医疗科技有限公司指导调研'  ,
        content:`<img src='http://47.97.84.75:8080/files/20240909/news20.jpg'>`,
    },
];